<template>

<div>
  {{cities_by_google}}
    <input type="text" v-model="location" style="background: gray">
    <ul>
      <li v-for="(result, i) in searchResults" :key="i">
       
        {{result[0].value}} {{result[1].value}} {{result[2] ? result[2].value:null}} 

      </li>
    </ul>
  </div>
</template>


<script>


    export default {

        data() {
            return {
              location: '',
              searchResults: [],
              cities_by_google: [],
              service: null // will reveal this later 🕵️
            }
        },
        metaInfo () {
          return {
            script: [{
              src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
              async: true,
              defer: true,
              callback: () => this.MapsInit() // will declare it in methods
            }]
          }
        },
        async mounted() {    
        },
        computed: {
        },
        watch: {
          location (newValue) {
            if (newValue) {
              this.service.getPlacePredictions({
                input: this.location,
                componentRestrictions: {country: ['us','pr']},
                types: ['(regions)']
              }, this.displaySuggestions)
            }
          }
        },        
        methods: {
          MapsInit () {
            this.service = new window.google.maps.places.AutocompleteService()
          },
          displaySuggestions (predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              this.searchResults = []
              return
            }
            this.searchResults = predictions.map(prediction => prediction.terms);

            this.data = predictions.map(prediction => prediction);
            
            this.cities_by_google = this.data.map(t => ({

                id: t.place_id,
                description: t.description,
                city_name: t.terms[0].value,
                code: t.terms[1] ? t.terms[1].value:null,
                country: t.terms[2] ? t.terms[2].value:null,
            }));

          }
        }        
    }
</script>